<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
          <div class="d-lg-flex align-items-center justify-content-between">
            <!-- Content -->
            <div class="mb-4 mb-lg-0">
              <h1 class="text-white mb-1">Thêm người dùng</h1>
              <p class="mb-0 text-white lead">
                Điền thông tin rồi ấn Thêm mới để thêm người dùng
              </p>
            </div>
            <div>
              <a href="/admin/user" class="btn btn-white ">Danh sách người dùng</a>&nbsp;
              <button type="button" v-on:click="onSubmit"
                      class="btn btn-success ">Thêm
                mới
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-12">
    <div class="container">
      <div id="userForm" class="bs-stepper">
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
            <div class="bs-stepper-content mt-5">
              <form v-on:submit.prevent="">
                <div class="card mb-3 ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label for="name" class="form-label">Họ tên (*)</label>
                          <input v-model="user.name" id="name" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3">
                          <label for="email" class="form-label">Email (*)</label>
                          <input v-model="user.email" id="email" class="form-control" type="email"
                                 required/>
                        </div>
                        <div class="mb-3">
                          <label for="password" class="form-label">Password (*)</label>
                          <input v-model="user.password" id="password" class="form-control" type="text"
                                 required/>
                        </div>
                        <div class="mb-3">
                          <label for="phone" class="form-label">SĐT</label>
                          <input v-model="user.phone" id="phone" class="form-control" type="text"/>
                        </div>
                        <div class="mb-3 d-flex align-middle">
                          <label class="form-label">Quyền: </label>
                          <div class="ms-3">
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="roleAdmin" v-model="user.role" value="admin"
                                       class="form-check-input">
                                <label class="form-check-label" for="roleAdmin">Admin</label>
                              </div>
                              <div class="form-check me-3">
                                <input type="radio" id="roleUser" v-model="user.role" value="user"
                                       class="form-check-input">
                                <label class="form-check-label" for="roleUser">User thường</label>
                              </div>
                              <div class="form-check me-3">
                                <input type="radio" id="roleReviewerAdmin" v-model="user.role" value="reviewer_admin"
                                       class="form-check-input">
                                <label class="form-check-label" for="roleReviewerAdmin">Điều phối chấm bài</label>
                              </div>
                              <div class="form-check me-3">
                                <input type="radio" id="roleReviewer" v-model="user.role" value="reviewer"
                                       class="form-check-input">
                                <label class="form-check-label" for="roleReviewer">Người chấm bài</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 mt-3 d-flex align-middle">
                          <label class="form-label">Trạng thái</label>
                          <div class="ms-3">
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="statusActive" v-model="user.status" value=1
                                       class="form-check-input">
                                <label class="form-check-label" for="statusActive">Hoạt động</label>
                              </div>
                              <div class="form-check">
                                <input type="radio" id="statusInactive" v-model="user.status" value=0
                                       class="form-check-input">
                                <label class="form-check-label" for="statusInactive">Tạm khóa</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Thumbnail v-on:setThumbnail="handleSetThumbnailEvent" :thumbnail="thumbnail"/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" title="Thêm người dùng"
         :message-detail="messageModalMessageDetail"/>

  <Media :mediaMode="mediaMode" v-on:setMedia="handleSetMediaEvent"/>
</template>

<script>
import Media from "@/components/Media";
import Thumbnail from "@/components/Thumbnail";
import Modal from "@/components/Modal";
import ApiService from "@/core/services/api.service";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'Adduser',
  components: {
    Media,
    Modal,
    Thumbnail
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        phone: "",
        password: "",
        role: "user",
        status: 1,
        thumbnail_id: null,
        thumbnail: {id: 0}
      },
      mediaMode: "thumbnail",
      thumbnail: null,
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
    }
  },
  methods: {
    handleSetThumbnailEvent() {
      this.mediaMode = "thumbnail";
    },
    handleSetMediaEvent(media) {
      this.thumbnail = media;
      this.user.thumbnail_id = parseInt(media.id);
    },
    onSubmit() {
      let query = `mutation($name: String!, $email: String!, $password: String!, $phone: String, $role: String!, $thumbnail_id: Int) {
        signup(name: $name, email: $email, password: $password, phone: $phone, role: $role, thumbnail_id: $thumbnail_id) {
          id
        }
      }`;

      ApiService.graphql(query, this.user)
          .then(({data}) => {
            if (data.data && data.data.signup) {
              this.messageModalMessage = "Thêm user thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.$router.push({name: 'AdminListUser'})
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    }
  },
  mounted() {

  }
}
</script>

<style>
.editor {
  height: 200px;
}
</style>
